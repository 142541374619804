import { useContext } from "react"
import { useMap } from "@emblautec/react-map-gl"
import { MainDrawContext } from "components/map/contexts/MainDrawContext/MainDrawContext"

export const useMainDraw = () => {
    const draw = useContext(MainDrawContext)
    const { mainMap } = useMap()

    if (draw === null) {
        throw new Error("Draw context is null")
    }

    return mainMap ? draw : null
}
